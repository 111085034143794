//
//TE Version 0.1.0
//

//
//The goal here is to provide a simple default component to use.
//
import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Helmet } from "react-helmet";

class TEHelmet extends Component {

	render() {
		const {
			charSet,
			title,
			description,
			children
		} = this.props

		return (
			<Helmet>
				<meta charSet={charSet} />
				<title>{title}</title>
				<meta name="description" content={description}/>
				{children}
			</Helmet>
		)
	}
}

TEHelmet.propTypes = {
	charSet 		: PropTypes.string,
	title 			: PropTypes.string,
	description 	: PropTypes.string,
}

TEHelmet.defaultProps = {
	charSet 		: 'utf-8',
	title 			: 'The Domestic Modern Women',
	description	 	: 'You can do anything, but not everything',
}

export default TEHelmet

/*
	//Removed for now
	<meta name='twitter:card' content='summary'/>
	<meta name='twitter:site' content='@PadscoutsInc'/>
	<meta name='twitter:title' content='PadScouts'/>
	<meta name='twitter:description' content={description}/>
	<meta name='twitter:image:src' content={image}/>

	<meta property='og:title' content='PadScouts' />
	<meta property='og:type' content='article' />
	<meta property='og:url' content='https://www.padscouts.com/'/>
	<meta property='og:image' content={image}/>
	<meta property='og:description' content={description}/>
	<meta property='og:site_name' content=" PadScouts"/>
	<meta property="fb:admins" content='1676241799113623'/>
*/
