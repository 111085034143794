import React, { Component } from 'react'
//import PropTypes from 'prop-types'
import Radium from 'radium'

import Navbar from './../Navbar'
import Footer from './../Footer'

import styles from './styles'

class FrontContentWrapper extends Component {
	render() {
		const { location, children } = this.props
		return (
			<div className='front-wrapper' style={styles.frontWrapper}>
				<Navbar location={location} />
				<div className='front-content-wrapper' style={styles.frontContentWrapper}>{children}</div>
				<Footer />
			</div>
		)
	}
}

FrontContentWrapper.propTypes = {}

FrontContentWrapper.defaultProps = {}


export default Radium(FrontContentWrapper)
