import { colors } from './../../../config/styles'

export default {
	content : {
		width				: '90%',
		maxWidth			: 600,
		margin				: '100px auto',
	},
	title : {
		position 			: 'relative',
		width 				: '100%',
		textAlign			: 'center',
		fontSize			: 24,
		marginBottom		: 20,
		color 				: colors.black,
		paddingLeft			: 30,
		paddingTop			: 30,
		paddingRight		: 30,
		paddingBottom		: 0,
	},
	message : {
		position			: 'relative',
		width 				: '100%',
		textAlign			: 'center',
		fontSize			: 18,
		lineHeight			: 1.25,
		marginBottom		: 30,
		padding 			: '0px 40px',
		color 				: colors.black,
	},
	buttonContainer : {
		borderBottomRightRadius 	: 20,
		borderBottomLeftRadius 		: 20,
		overflow 					: 'hidden',
	},
	button : {
		position			: 'relative',
		width 				: '100%',
		textAlign			: 'center',
		fontSize			: 18,
		lineHeight			: 2,
		borderRadius 		: 0,
		backgroundImage 	: 'none',

		color 				: colors.black,
		backgroundColor		: colors.white,
		borderTop			: `1px solid ${colors.black}`,
		borderRight			: 'none',
		borderBottom		: 'none',
		borderLeft			: 'none',
		transition 			: 'color 0.2s ease-in, background-color 0.2s ease-in, border 0.2s ease-in',

		':hover' : {
			color 				: colors.white,
			backgroundColor		: colors.black,
			borderTop			: `1px solid ${colors.black}`,
			borderRight			: 'none',
			borderBottom		: 'none',
			borderLeft			: 'none',
		},
		':active' : {
			color 				: colors.white,
			backgroundColor		: colors.black,
			borderTop			: `1px solid ${colors.black}`,
			borderRight			: 'none',
			borderBottom		: 'none',
			borderLeft			: 'none',
		}
	}
}
