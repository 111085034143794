import axios from 'axios'
import settings from './../config/settings'

export const sendEmail = (data) => {
	/*if (settings.dev){
		console.log("Email Sending Blocked On Development")
		return
	}*/

	return axios.post(settings.EMAIL_API_ROUTE, data)
}

