//
//TE Version 0.1.0
//

import React, { Component } from 'react'
import Radium from 'radium'
import PropTypes from 'prop-types'
import styles from './styles'

class TEInput extends Component {

	render() {
		const {
			style,
			inputData,
			...rest
		} = this.props

		const {
			value,
			//To be used later
			//validLabel,
			//valid,
		} = inputData

		return (
			<input 
				value={value}
				style={[
					styles.input,
					rest.disabled && styles.disabled,
					style,
				]}
				{...rest}
			/>
		)
	}
}

TEInput.propTypes = {
	style			: PropTypes.object,
	type 			: PropTypes.string.isRequired,
	inputData 		: PropTypes.shape({
		value 	: PropTypes.oneOfType([
			PropTypes.string,
			PropTypes.number,
			PropTypes.bool,
		]),
		validLabel	: PropTypes.string.isRequired,
		valid		: PropTypes.bool.isRequired,
	}),
	onChange 		: PropTypes.func.isRequired,
	disabled		: PropTypes.bool
}

TEInput.defaultProps = {
	type 		: 'text',
	disabled 	: false
}

export default Radium(TEInput)
