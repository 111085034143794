//
//TE Version 0.1.0
//

import React, { Component } from 'react'
import PropTypes from 'prop-types'
import Radium from 'radium'

import styles from './styles'

class TELabel extends Component {
	render() {
		const {
			labelText,
			style,
			htmlFor,
			required,
			disabled,
		} = this.props
		return (
			<label 
				style={[
					styles.label,
					disabled && styles.disabled,
					!htmlFor && styles.noHtmlFor,
					style,
				]}
				htmlFor={htmlFor}
			>
				{labelText + (required ? ' *' : '')}
			</label>
		)
	}
}

TELabel.propTypes = {
	labelText 		: PropTypes.string,
	style			: PropTypes.object,
	htmlFor			: PropTypes.string
}

TELabel.defaultProps = {}

export default Radium(TELabel)
