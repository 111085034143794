//
//TE Version 0.1.0
//

import React from 'react';
//import PropTypes from 'prop-types';

import TEEmailLayout from './../TEEmailLayout'

import styles from './styles';

//Reference : http://www.mhschool.com/ss/ca/images/img_g3_personalletter.gif
const TESimpleEmail = (props) => {
	return (
		<TEEmailLayout>
			<tr>
				<td style={styles.oneColumn}>
					<table width="100%" style={styles.table}>
						<tbody>
							<tr>
								<td style={styles.inner}>
									{ props.title && 
										<h1 style={styles.title}>{props.title}</h1> 
									}
									{ props.subtitle && 
										<h2 style={styles.subtitle}>{props.subtitle}</h2> 
									}
									{ props.body && 
										<p style={styles.message}>{props.body}</p> 
									}
									{ props.closing && 
										<p style={styles.closing}>{props.closing}</p> 
									}
									{ props.signature && 
										<p style={styles.signature}>{props.signature}</p> 
									}
								</td>
							</tr>
						</tbody>
					</table>
				</td>
			</tr>
		</TEEmailLayout>
	);
}

TESimpleEmail.propTypes = {}

TESimpleEmail.defaultProps = {};

export default TESimpleEmail;
