//
//TE Version 0.1.0
//

import React, { Component } from 'react'
import PropTypes from 'prop-types'
import Radium from 'radium'
import styles from './styles'

class TEButton extends Component {

	render() {
		const {
			style,
			children,
			...rest
		} = this.props

		return (
			<button 
				type="button"
				style={[
					styles.button,
					rest.disabled && styles.disabled,
					style,
				]}
				{...rest}
			>
				{children}
			</button>
		)
	}
}

TEButton.propTypes = {
	style		: PropTypes.object,
	onClick 	: PropTypes.func.isRequired,
}

TEButton.defaultProps = {}

export default Radium(TEButton)
