import { colors } from './../../config/styles';

export default {
	container : {
		position 	: 'relative',
		width 		: '90%',
		maxWidth	: '400px',
		margin		: '0px auto',
		padding 	: '100px 0px',
	},
	h1 : {
		position	: 'relative',
		textAlign	: 'center',
		color 		: colors.darkGray,
		margin 		: 0,
	}
}
