import { colors } from './../../../config/styles'

export default {
	body : {
		marginTop 			: '0 !important',
		marginBottom		: '0 !important',
		marginRight			: '0 !important',
		marginLeft			: '0 !important',
		paddingTop			: 0,
		paddingBottom		: 0,
		paddingRight		: 0,
		paddingLeft			: 0,
		backgroundColor		: colors.white,
	},
	wrapper : {
		width					: '100%',
		tableLayout				: 'fixed',
		WebkitTextSizeAdjust 	: '100%',
		msTextSizeAdjust		: '100%'
	},
	webkit : {
		maxWidth 			: 600,
		marginTop 			: 0,
		marginBottom 		: 0,
		marginRight 		: 'auto',
		marginLeft 			: 'auto'
	},
	outer : {
		borderSpacing			: 0,
		fontFamily				: 'Verdana',
		color 					: colors.black,
		backgroundColor			: colors.white,
		backgroundImage			: 'none',
		backgroundRepeat		: 'repeat',
		backgroundPosition		: 'top left',
		backgroundAttachment	: 'scroll',
		margin					: '20px auto',
		width					: '100%',
		maxWidth				: 600,
		textAlign				: 'center'
	},
	oneColumn : {
		paddingTop			: 0,
		paddingBottom		: 0,
		paddingRight		: 0,
		paddingLeft			: 0
	},
	table : {
		borderSpacing			: 0,
		fontFamily				: 'Verdana',
		color 					: colors.black,
		backgroundColor			: 'transparent',
		backgroundImage			: 'none',
		backgroundRepeat		: 'repeat',
		backgroundPosition		: 'top left',
		backgroundAttachment	: 'scroll'
	},
	inner : {
		textAlign			: 'center',
		paddingTop			: 30,
		paddingBottom		: 10,
		paddingRight		: 10,
		paddingLeft			: 10,
		width				: '100%'
	},
	image : {
		borderWidth: 0
	},
	p : {
		textAlign		: 'center',
		color 			: colors.black,
		marginBottom	: 10,
		lineHeight		: '1.5',
		fontSize		: 16,
	}
}
