//import { colors } from './../../../config/styles'

export default {
	mainPanel : {
		position        : 'relative',
		width           : '100%',
		minHeight       : '100%',
	},
	frontContentWrapper : {
		minHeight   : 'calc(100vh - 517px)',

		'@media (max-width: 650px)' : {
	       //minHeight   : 'calc(100vh - 160px)'
		},
		'@media (max-width: 600px)' : {
	        //minHeight   : 'calc(100vh - 210px)'
		},
	}
}
