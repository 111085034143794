//
//TE Version 0.1.0
//

import React from 'react';
//import PropTypes from 'prop-types';

//import settings from './../../../config/settings'

//import images from './../../../config/images'
import styles from './styles';


const TEEmailLayout = (props) => {
	//const baseUrl = settings.dev ? settings.DEVELOPMENT.BASE_URL : settings.PRODUCTION.BASE_URL

	return (
		<html xmlns="http://www.w3.org/1999/xhtml">
			<head>
				<meta httpEquiv="Content-Type" content="text/html; charset=utf-8" />
			</head>
			<body style={styles.body} >
				<center style={styles.wrapper}>
					<div style={styles.webkit}>
						<table style={styles.outer}>
							<tbody>
								{ props.children }
							</tbody>
						</table>
					</div>
				</center>
			</body>
		</html>
	);
}

TEEmailLayout.propTypes = {}

TEEmailLayout.defaultProps = {};

export default TEEmailLayout;
