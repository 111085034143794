//
//TE Version 0.1.0
//

import React, { Component } from 'react'
import PropTypes from 'prop-types'
import Radium from 'radium'

import styles from './styles'

class TEForm extends Component {

	render() {
		const {
			style,
			onSubmit,
			children,
		} = this.props

		return (
			<form 
				onSubmit={onSubmit}
				style={[
					styles.form,
					style,
				]}
			>
				{children}
			</form>
		)
	}
}

TEForm.propTypes = {
	style		: PropTypes.object,
	onSubmit 	: PropTypes.func
}

TEForm.defaultProps = {}

export default Radium(TEForm)
