import { colors } from './../../../config/styles'

export default {
	content : {
		position 			: 'absolute',
	    top 				: '50%',
	    left 				: '50%',
	    WebkitTransform		: 'translate(-50%, -50%)',
	    msTransform			: 'translate(-50%, -50%)',
	    transform 			: 'translate(-50%, -50%)',
		backgroundColor		: 'rgba(0,0,0,0.5)',
		padding 			: '60px',
		textAlign			: 'center',
	},
	spinner : {
	    position 		: 'relative',
	    width 			: '80px',
	    height 			: '80px',
	    display 		: 'inline-block',
	    marginBottom 	: '30px',
	},
	ring : {
	    position 		: 'absolute',
	    borderRadius 	: '50%',
	    overflow 		: 'hidden',
	    borderTop 		: '3px solid transparent',
	    borderRight 	: '1px solid transparent',
	    borderBottom 	: '1px solid transparent',
	    borderLeft 		: '1px solid transparent',
	},
	message : {
		position 			: 'relative',
		width 				: '100%',
		textAlign			: 'center',
		fontSize			: '24px',
		color 				: colors.white,
	}
}
